
.dashboard {
  display: flex;
  flex-direction: column;
}

.dashboard-cards {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.dashboard_charts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
}



.dashboard-card {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 150px;
  box-shadow: 0 0 8px lightgray;
  border-radius: 7px;
  margin: 10px;
  padding: 15px;
}

.dashboard-card_icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #ededed;
}

.dashboard-card_icon {
  font-size: 2rem !important;;
}

.dashboard-card_text {
  font-size: 1.4rem !important;
  font-weight: 400;
}

.dashboard-card_data {
  font-size: 3rem !important;
  font-weight: 500;

}

.flex {
  display: flex !important;
  width: 100% !important;
}

.items-center {
  align-items: center;
}

.space-between {
  justify-content: space-between !important;
}

.flex-col {
  flex-direction: column !important;
}
