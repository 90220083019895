
.upload-input #file-upload-button {
  display: none!important;
}

.invoice-image {
  background-size: contain;
  background-repeat: no-repeat;
  height: 140px;
  width: 100%!important;
  margin-top:-70px;
  /*position: absolute;*/
  /*right: 20%;*/
  /*top:10px*/
}
