.flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.iconFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.main {
    margin: 1rem;
    font-size: 12px;
}

.logo {
    width: 300px;
    height: auto;
    margin: 1rem auto; /* Adjust this margin as needed */
}

.icons {
    margin-top: 2px; /* Adjust this margin as needed */
    color: red;
}

.accordionRow {
    background-color: #EC0C31; /* Red background color */
    border: 1px solid #EC0C31; /* Red border */
    border-radius: 7px;
    margin-top: 12px;
    overflow: hidden;
    width: 700px;
}

.secondAccordionRow {
    background-color: #F5F5F5; /* Red background color */
    border: 1px solid #F5F5F5; /* Red border */
    border-radius: 15px;
    margin-top: 12px;
    overflow: hidden;
    width: 700px;
}

.accordionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.accordionLabels {
    display: flex;
    align-items: center;
}

.accordionLabels > span {
    margin-right: 10px; /* Space between labels */
}

.accordion-icon {
    transition: transform 0.3s ease-in-out;
}

.accordion-icon.expanded {
    transform: rotate(45deg);
}

.accordion-content {
    padding: 10px;
    border-top: 1px solid #ff0000; /* Red border */
}

.table {
    width: 100%;
}

.table tr > td {
    font-size: 0.85rem;
}

.table tbody tr {
    border-bottom: 1px dotted #C9C9C9;
}

.table tbody tr:last-child {
    border: none;
}

.table td:last-child {
    text-align: end;
}

.table tr {
    line-height: 2rem;
}

.title {
    font-size: 13px;
    border-bottom: 1px solid #C9C9C9;
    text-align: left;
}

.tableRowFields {
    font-size: 15px;
    border-bottom: 1px solid #C9C9C9;
    text-align: left;
    padding: 5px;
}


.totalContainer {
    width: 50%;
    margin-top: 3rem;
    float: right;
}

.total {
    padding: 0.25rem 0;
}

.total p {
    flex: 1;
    text-align: end;
}

.total p:first-child {
    font-weight: bold;
}

.total p:last-child {
    border-bottom: 1px dotted #C9C9C9;
}

.fontBold {
    font-weight: bold;
}

.marginTop50 {
    margin-top: 50px;
    width: 500px;
}

.importantInfo {
    text-align: center;
    font-weight: bold;
    font-size: 0.85rem;
    border: 1px dotted #C9C9C9;
}

.banks {
    align-items: flex-start;
    gap: 5px;
}

.banks div {
    flex: 1;
    word-wrap: break-word;
}





/* styles.module.css */
.accordionLabels {
    display: flex;
    justify-content: space-between; /* This will space the spans evenly across the container */
}

.leftLabel {
    flex: 1; /* This will make the left label take up as much space as possible */
    text-align: left; /* Align the text to the left */
}

.centerLabel {
    flex: 0; /* Align the text to the center */
}

.rightLabel {
    flex: 1; /* This will make the right label take up as much space as possible */
    text-align: right; /* Align the text to the right */
}
